import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { config, animated, useSpring } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

type PageProps = {
  data: {
    projects: {
      nodes: {
        title: string
        slug: string
        cover_image: string
        cover: ChildImageSharp
      }[]
    }
    images: {
      nodes: {
        name: string
        localFile: {
          childImageSharp: {
            fluid: {
              aspectRatio: number
              src: string
              srcSet: string
              sizes: string
              base64: string
              tracedSVG: string
              srcWebp: string
              srcSetWebp: string
            }
          }
        }
      }[]
    }
    notFound: {
      childImageSharp: {
        fluid: {
          aspectRatio: number
          src: string
          srcSet: string
          sizes: string
          base64: string
          tracedSVG: string
          srcWebp: string
          srcSetWebp: string
        }
      }
    }
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 50vw;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 60vw;
  }
`

const Projects: React.FunctionComponent<PageProps> = ({ data: { projects, images, notFound } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#000">
      <SEO title="Gallery | ToriArt" />
      <Area style={pageAnimation}>
        {projects.nodes.map(project => (
          <GridItem key={project.slug} to={project.slug} aria-label={`View project "${project.title}"`}>
            {/* Add placeholder image for special case */}
            {/*
            Using "Optional Chaining" to make sure that find() does not returns a undefined or null
            and "Nullish Coalescing" operator as conditional if undefined/null happens
            https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#optional-chaining
             */}
            <Img
              fluid={
                images.nodes.find(img => img.name === project.cover_image)?.localFile.childImageSharp.fluid ??
                notFound.childImageSharp.fluid
              }
            />
            <span>{project.title}</span>
          </GridItem>
        ))}
      </Area>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query Projects {
    images: allDropboxNode(filter: { path: { regex: "/^(.*(000){1}\\.(jpg))/" }, name: {regex: "/^(.*\\.(?!(yaml)))/"} }, sort: { fields: name, order: ASC }) {
      nodes {
        name
        localFile {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    projects: allProjectsYaml {
      nodes {
        title
        slug
        cover_image
      }
    }
    notFound: file(sourceInstanceName: { eq: "images" }, name: { eq: "not-found" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
